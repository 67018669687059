const scoreRanges = [[0, 35], [36, 45], [46, 53], [54, 60], [61, 71], [72, 999]];

const descriptions = [
  "你的思考模式比較跳躍.聰明.好奇心強.個性直想到什麼說什麼.與人對話常常只聽自己想聽的.對於世俗的眼光不太在乎.活在自己的世界裡.常常會有驚人之舉",
  "你是一個主動.積極.樂觀喜歡交朋友的人.充滿企圖心及行動力.因為能力不錯相對自我意識容易高漲.不太聽得進去別人的建議所以可能因為衝動獨斷做錯事而不自知.老闆對於這類人又愛又恨.同事對於這類人要嘛視為榜樣學習.要嘛避而遠之.做事不喜歡拖拖拉拉.多得多勞是你內心對事物的評價.但也因為鋒頭太健容易招來小人.忍得住.放得下是你必修的人生課程.",
  "你是個親和力十足的人.有能力但常常會有千里馬遇不到伯樂的遺憾.因為容易親近且熱心助人所以朋友自然很多.相對的交際應酬也會占用你很多時間.整天忙忙碌碌卻感覺一事無成.時勢造英雄.如果遇到賞識的人或是適合自己本性發揮的環境.其實你是極俱爆發力的!",
  "理想很豐滿.現實很骨感.你雖然喜歡穩扎穩打但是心中依然有夢.遇到事情容易猶豫不決很容易喪失一些機會.肯做.願意做是你給人的第一印象.所以深得老闆喜愛.同事之間相處融洽堪稱好好先生.",
  "知足常樂.隨遇而安是你最好的形容詞.沉默不喜歡與人爭論.細心.謹守本分專注於自己擅長的領域.也許朋友不多.但是在該領域中常常獨占鰲頭.頗有成就",
  "你是一個重理論具分析力的人.年輕時看起來隨和但是內心卻有一把尺.年紀愈大愈不容易親近.為人也愈沈穩內斂.凡事都喜歡有憑有據.因為自我心中有一套標準所以較為固執.處事深思熟慮行動謹慎但缺乏融通性.喜歡用試探性的口吻與人對話.旁人不太容易猜得出你心中在想什麼?懂你的人永遠懂你.不懂你的人你也不屑一顧!"
];

export { scoreRanges, descriptions }

export default function(score) {
  let scoreIndex;
  for (let i=0; i < scoreRanges.length; i++) {
    if (score >= scoreRanges[i][0] && score <= scoreRanges[i][1]) {
      scoreIndex = i;
      break;
    }
  }
  return descriptions[scoreIndex];
}
