<template>
  <div class="result1">
    <div class="framing-x bg-content">
      <article class="article">
        <h2 class="h2 text-center">
          {{ $root.$data.BasicInfo1.name }}，<br>你的人格分數座落在：
        </h2>

        <div class="score">
          <div class="score-number">{{ score }}</div>
          <div class="score-unit mt-1">分</div>
        </div>

        <h3 class="result-title text-primary text-center mb-lg-4">分析說明：</h3>

        <div class="infoBox">
          <div class="text-small">{{ description }}</div>
        </div>

        <div class="text-center deco-triangle">
          <img alt="next" src="../assets/triangle-down.svg">
        </div>

      </article>
    </div>
    <div class="framing-x framing-y">
      <router-link :to="$route.meta.next" class="btn btn-primary w-100 footer-button">繼續職業分析測驗</router-link>
    </div>
  </div>
</template>

<script>
import result1 from '../data/result1.js'

export default {
  computed: {
    score() {
      return this.$root.$data.responsePayload.Sheet1.total || '0';
    },
    description() {
      let score = parseInt(this.score);
      return result1(score);
    }
  }
}
</script>

<style scoped lang="scss">
.article {
  padding-bottom: 1rem;
}
.score {
  margin-top: 0.8125rem;
  margin-bottom: .5rem;
}
.score-unit {
  margin-top: 4px;
}
.deco-triangle {
  margin-top: 1rem;
  line-height: .5rem;
}

@media screen and (min-width: 960px) {
.article {
  padding-bottom: 1.25rem;
}
.score {
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
}
.score-unit {
  margin-top: 11px;
}
.infoBox {
  padding: 2.5rem 4.25rem;
}
.deco-triangle {
  margin-top: 1.75rem;
  line-height: 1rem;

  & > img {
    width: 1.5rem;
  }
}
}
</style>
